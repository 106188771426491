import React from 'react';
import { bool, string } from 'prop-types';
import styled from 'styled-components';

import { FormElementError } from '../FormElementError/FormElementError';
import { PALETTE } from '../../../themes/constants';

export const CheckboxLabel = styled.label`
  display: inline-block;
  & > * {
    padding-top: 2px;
  }

  label {
    color: ${props => (props.$disabled ? PALETTE.grays.gray3 : 'inherit')};
    font-size: 0.875rem;
    font-weight: ${props => (props.$bold ? 'bold' : 'normal')};
  }
`;

export const CheckboxContainer = styled.div`
  align-items: ${({ verticalAlign }) => {
    switch (verticalAlign) {
      case 'bottom': {
        return 'flex-bottom';
      }
      case 'middle': {
        return 'center';
      }
      default: {
        return 'flex-top';
      }
    }
  }};
  ${props => !props.$disabled && 'cursor: pointer;'}

  // TEMP BOOTSTRAP YANK CARRYOVER
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }

  .form-check-input {
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  input[type='checkbox'],
  input[type='radio'] {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }
`;

export const CheckboxSubtext = styled.div`
  font-size: 0.875rem;
`;

export const CheckboxError = styled(FormElementError)`
  display: block;
  margin-top: 0;
`;

export const StyledIcon = styled.svg`
  cursor: pointer;
  display: inline-block;
  margin-right: 0.625rem;
  width: 25px;
  height: 25px;
`;

export const CheckboxIconWrapper = styled.div`
  display: inline-block;
  &:focus-within {
    ${StyledIcon} {
      outline: 3px solid ${PALETTE.colors.gold};
    }
  }
`;

export const CheckboxIcon = ({ checked, className, disabled }) => (
  <StyledIcon
    className={className}
    data-value={checked ? 'checked' : 'unchecked'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 27"
    focusable="false"
    aria-hidden="true"
  >
    <g stroke={disabled ? PALETTE.grays.gray4 : PALETTE.grays.gray9}>
      <path
        d="M.5.5h26v26H.5z"
        fill={disabled ? PALETTE.grays.gray1 : 'none'}
      />
      {checked && (
        <path
          d="M6.5 16.167L11 20 21.5 8.5"
          fill="none"
          strokeLinecap="square"
          strokeWidth="2"
        />
      )}
    </g>
  </StyledIcon>
);

CheckboxIcon.defaultProps = {
  checked: false,
  className: null,
  disabled: false,
};

CheckboxIcon.propTypes = {
  checked: bool,
  className: string,
  disabled: bool,
};
