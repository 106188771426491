import React from 'react';
import qs from 'qs';
import {
  Card,
  CardSection,
  Layout,
  SectionHeader,
  PageHeader,
  Flashy,
  Button,
  Gutter,
  Grid,
  GridRow,
  GridColumn,
  colors,
} from '@axiom/ui';
import { Account } from '@axiom/validation';

import EndCardImage from '../../public/images/DiscoverTransparentBkg.png';

import { CardLayout } from './DiscoverTalentEndCardStyles';

export const DiscoverTalentEndCard = ({
  account,
  forIndustryCarousel = false,
}: {
  account: Account;
  forIndustryCarousel?: boolean;
}) => {
  return (
    <Card stretched name="DISCOVER_TALENT_END_CARD">
      <CardSection
        background={{
          image: EndCardImage,
          position: 'right top',
          color: colors?.controlHoverPrimary,
          size: 'contain',
        }}
      >
        <CardLayout position="middle">
          <Grid>
            <GridRow>
              <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
                <Layout
                  direction="vertical"
                  position="left"
                  verticalGutter="16px"
                >
                  <PageHeader>
                    <Flashy bold color="textReversed">
                      Go <br /> Further.
                    </Flashy>
                  </PageHeader>
                  <SectionHeader name="END_CARD_DESCRIPTION">
                    <Flashy color="textReversed">
                      Browse more of Axiom’s top legal talent
                      {forIndustryCarousel ? ' in your industry.' : '.'}
                    </Flashy>
                  </SectionHeader>
                  <Gutter bottom="24px" />
                  <Button
                    name="BROWSE_TALENT_BUTTON"
                    to={`/access-legal-talent/page/1${qs.stringify(
                      { industries: [account?.industryValue] },
                      {
                        addQueryPrefix: true,
                      }
                    )}`}
                  >
                    Browse Talent
                  </Button>
                </Layout>
              </GridColumn>
              <GridColumn />
            </GridRow>
          </Grid>
        </CardLayout>
      </CardSection>
    </Card>
  );
};
