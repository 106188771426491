import { shape, string, bool } from 'prop-types';

import FormStore from '../classes/form-store';
import { AjvUtil } from '../utils/ajv-util';
import { postExternalSearchReachOut } from '../api/hubspot/hubspot';
import { HubspotUtil } from '../utils/hubspot-util';

import { FormTalentSearchFiltersStore } from './form-talent-search-filters';

const FormExternalSearchReachOutModalValidation = AjvUtil.compileSchema({
  required: ['firstName', 'lastName', 'email'],
  properties: {
    firstName: {
      title: 'First Name',
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    lastName: {
      title: 'Last Name',
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    email: {
      title: 'Email',
      type: 'string',
      format: 'email',
      minLength: 1,
      maxLength: 255,
      errorMessage: 'Please enter a valid email',
    },
    phoneNumber: {
      title: 'Phone Number',
      basicPhoneNumber: 'phoneNumber',
    },
    searchFilters: {
      title: 'Search Filters',
      type: 'object',
    },
  },
});

class FormExternalSearchReachOutModalStoreClass extends FormStore {
  getDataShape() {
    return shape({
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      searchFilters: FormTalentSearchFiltersStore.getDataShape(),
      createAccount: bool,
    });
  }

  load(user) {
    return this.setState({
      firstName: user?.firstName || null,
      lastName: user?.lastName || null,
      email: user?.email || null,
      phoneNumber: user?.phoneNumber || null,
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      FormExternalSearchReachOutModalValidation,
      formData
    );
  }

  submit(changedFields, formData) {
    const dataUTM = HubspotUtil.parseURLDataUtm();
    const utmArr = Object.entries(dataUTM).map(entry => ({
      name: entry[0],
      value: entry[1],
    }));
    const data = {
      fields: [
        { name: 'firstname', value: formData.firstName },
        { name: 'lastname', value: formData.lastName },
        { name: 'email', value: formData.email },
        {
          name: 'agree_to_terms_of_service',
          value: true,
        },
        { name: 'create_account', value: formData.createAccount || false },
        ...utmArr,
      ],
    };

    if (formData.phoneNumber) {
      data.fields.push({
        name: 'phone',
        value: formData.phoneNumber.replaceAll(/[+() -]/g, ''),
      });
    }

    if (changedFields.searchFilters) {
      data.fields.push({
        name: 'filters',
        value: JSON.stringify(changedFields.searchFilters),
      });
    }

    return postExternalSearchReachOut(data);
  }
}

export const FormExternalSearchReachOutModalStore =
  new FormExternalSearchReachOutModalStoreClass();
