import {
  CandidatesConst,
  PracticeAreaConst,
  ProfessionConst,
} from '@axiom/const';
import { OccupationTypeUtil } from '@axiom/ui';

const { FullTime, PartTime } = CandidatesConst;

const { PracticeAreas } = PracticeAreaConst;

const alwaysShowKeys = {
  PRACTICE_AREA: 'PRACTICE_AREA',
  INDUSTRIES: 'INDUSTRIES',
  COUNTRIES: 'COUNTRIES',
  STATES: 'STATES',
};

const alwaysShowKeysAlphabetical = new Set([
  alwaysShowKeys.INDUSTRIES,
  alwaysShowKeys.PRACTICE_AREA,
  alwaysShowKeys.STATES,
]);

/**
 * The orders number in the object below are
 * dictated by Product
 * */
const alwaysShowData = {
  [alwaysShowKeys.PRACTICE_AREA]: {},
  [alwaysShowKeys.INDUSTRIES]: {},
  [alwaysShowKeys.COUNTRIES]: {
    'United States': 0,
    'United Kingdom': 1,
    Canada: 2,
    Singapore: 3,
    'Hong Kong': 4,
    Germany: 5,
    Switzerland: 6,
  },
  [alwaysShowKeys.STATES]: {},
};

const toGroupByPrefixes = {
  unitedStates: { findBy: /^(US - )/i, alwaysShowCount: 5 },
  canada: { findBy: /^(CANADA - )/i, alwaysShowCount: 5 },
  europe: { findBy: /^(UK - |EU - |Switzerland)/i, alwaysShowCount: 5 },
  apac: { findBy: /^(Asia - |AUS - |NZ)/i, alwaysShowCount: 3 },
};

const barredLocationsGroupings = (allBL, barredLocations) => {
  const hasGrouping = new Set();
  const barredLocs = allBL
    .map(ba => {
      return {
        ...ba,
        checked: barredLocations?.includes(ba.id),
      };
    })
    .reduce((obj, data) => {
      Object.entries(toGroupByPrefixes).forEach(([key, config]) => {
        if (config.findBy.test(data.name)) {
          if (!obj[key]) {
            obj[key] = {
              alwaysShow: [],
              showHide: [],
            };
          }

          const objKey =
            obj[key].alwaysShow.length < config.alwaysShowCount
              ? 'alwaysShow'
              : 'showHide';
          obj[key][objKey].push(data);

          hasGrouping.add(data.name);
        }
      });

      if (!hasGrouping.has(data.name)) {
        if (!obj.other) {
          obj.other = {
            alwaysShow: [],
            showHide: [],
          };
        }

        const objKey =
          obj.other.alwaysShow.length < 5 ? 'alwaysShow' : 'showHide';
        obj.other[objKey].push(data);
      }

      return obj;
    }, {});

  return barredLocs;
};

/*
  THIS IS A QUICK FIX FOR FILTERING THE TALENT SEARCH FILTER OPTIONS
  THIS SHOULD BE REMOVED ONCE THE PROPER BE/FE WORK ARE IMPLEMENTED
  Current Usage:
    - TalentSearchFilters
    - preloaded-barred-locations-store
    - preloaded-practice-areas-store
*/

export const WHITELISTED_COUNTRY_CODES = new Set([
  'US',
  'HK',
  'CA',
  'SG',
  'UK',
  'DE',
  'CH',
  'AU',
]);

export const EXCLUDED_PRACTICE_AREAS = [PracticeAreas.Unknown];

export const organizeData = async (
  allFilters = {},
  defaultSort,
  {
    search,
    sort,
    practiceAreaId = [],
    industries = [],
    yearsOfExperience = [],
    addressCountryCode = [],
    state = [],
    profession = [],
    weeklyAvailabilityLabel = [],
    barredLocations = [],
  },
  user
) => {
  const reducerFn = (obj, data, checkData, alwaysShowKey) => {
    const item = {
      ...data,
      checked: checkData?.includes(data.id),
    };

    const alwaysShowIndex = alwaysShowData[alwaysShowKey][item.name];

    /**
     * Do to the set ordering in the alwaysShowData; every item has a set array index,
     * the array may end up with Y populated indices but have X length.
     * Example: [, 'cat', 'dog', , , 'fish'] >> length of 6
     * There is logic below that cleans this up.
     * */
    if (alwaysShowIndex !== undefined) {
      obj.alwaysShow[alwaysShowIndex] = item;
    } else if (
      alwaysShowKeysAlphabetical.has(alwaysShowKey) &&
      obj.alwaysShow.length < 5
    ) {
      obj.alwaysShow.push(item);
    } else {
      obj.showHide.push(item);
    }

    return obj;
  };

  const getItems = (data, fullDataSet, alwaysShowKey) => {
    const items = data.reduce(
      (obj, item) => reducerFn(obj, item, fullDataSet, alwaysShowKey),
      {
        alwaysShow: [],
        showHide: [],
      }
    );

    /**
     * Removing NULL values from configured filters,
     * do to the value not being returned from the API
     * Example: [, 'cat', 'dog', , , 'fish'] >> length of 6
     * If the API does not return the index 3 item
     * we end up with a NULL in the array. The empty
     * indices translate to NULL.
     *
     * The below fixing down stream issues.
     * */
    items.alwaysShow = items.alwaysShow.filter(a => !!a);

    return items;
  };

  const data = {
    search,
    sort: sort || defaultSort,
    categories: [],
    hasSearched: !!search,
  };

  if (allFilters.practiceArea?.length) {
    data.categories.push({
      name: 'practiceAreaId',
      title: 'Practice Area',
      isOpen: true,
      selectedCount: practiceAreaId?.length || 0,
      items: getItems(
        allFilters.practiceArea.sort((a, b) => a.name.localeCompare(b.name)),
        practiceAreaId,
        alwaysShowKeys.PRACTICE_AREA
      ),
    });
  }

  if (allFilters.industries?.length) {
    data.categories.push({
      name: 'industries',
      title: 'Industry',
      isOpen: true,
      selectedCount: industries?.length || 0,
      items: getItems(
        allFilters.industries.sort((a, b) => a.name.localeCompare(b.name)),
        industries,
        alwaysShowKeys.INDUSTRIES
      ),
    });
  }

  if (allFilters.yearsOfExperience?.length) {
    data.categories.push({
      name: 'yearsOfExperience',
      title: 'Years Of Experience',
      isOpen: false,
      selectedCount: yearsOfExperience?.length || 0,
      items: {
        alwaysShow: allFilters.yearsOfExperience.map(yoe => {
          const found = yearsOfExperience?.find(yearOfExp => {
            return Number.parseInt(yearOfExp.start, 10) === yoe.id.start;
          });

          return {
            ...yoe,
            name: `${yoe.name} yrs`,
            checked: found !== undefined,
          };
        }),
        showHide: [],
      },
    });
  }

  if (allFilters.addressCountryCode?.length) {
    data.categories.push({
      name: 'addressCountryCode',
      title: 'Country',
      isOpen: false,
      selectedCount: addressCountryCode?.length || 0,
      items: getItems(
        allFilters.addressCountryCode,
        addressCountryCode,
        alwaysShowKeys.COUNTRIES
      ),
    });
  }

  if (allFilters.state?.length) {
    data.categories.push({
      name: 'state',
      title: 'State',
      isOpen: false,
      selectedCount: state?.length || 0,
      items: getItems(
        allFilters.state.sort((a, b) => a.name.localeCompare(b.name)),
        state,
        alwaysShowKeys.STATES
      ),
    });
  }

  if (allFilters.profession?.length) {
    data.categories.push({
      name: 'profession',
      title: 'Legal Role',
      isOpen: false,
      selectedCount: profession?.length || 0,
      items: {
        alwaysShow: Object.entries(ProfessionConst).map(([key, { name }]) => ({
          id: key,
          name:
            key === 'lawyer' &&
            OccupationTypeUtil.userShouldSeeConsultantLabel(user)
              ? 'Consultant'
              : name,
          checked: profession?.includes(key),
        })),
        showHide: [],
      },
    });
  }

  data.categories.push({
    name: 'weeklyAvailabilityLabel',
    title: 'Availability',
    isOpen: false,
    selectedCount: weeklyAvailabilityLabel?.length || 0,
    items: {
      alwaysShow: [
        {
          id: FullTime,
          name: 'Full-time',
          checked: weeklyAvailabilityLabel?.includes(FullTime),
        },
        {
          id: PartTime,
          name: 'Part-time',
          checked: weeklyAvailabilityLabel?.includes(PartTime),
        },
      ],
      showHide: [],
    },
  });

  if (allFilters.barredLocations?.length) {
    data.categories.push({
      name: 'barredLocations',
      title: 'Admission',
      isOpen: false,
      selectedCount: barredLocations?.length || 0,
      items: barredLocationsGroupings(
        allFilters.barredLocations.sort((a, b) => a.name.localeCompare(b.name)),
        barredLocations
      ),
    });
  }

  return data;
};
