import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  DataTestUtil,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Label,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Layout,
  Paragraph,
  useApi,
} from '@axiom/ui';
import { CountryCodesConst } from '@axiom/const';

import MoreProfiles from '../../public/images/more-profiles.png';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { Input } from '../FormElements/Input/Input';
import { FormExternalSearchReachOutModalStore } from '../../stores/form-external-search-reach-out-modal-store';
import { PreloadedBarredLocationsStore } from '../../stores/preloaded-barred-locations-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { isAFCUser } from '../../utils/roles';
import { CreateAccountTosPrivacyNotice } from '../CreateAccountTosPrivacyNotice/CreateAccountTosPrivacyNotice';
import { TaxonomyApi } from '../../api/taxonomy/taxonomy';

import {
  TSROMColoredText,
  TSROMCustomError,
} from './ExternalSearchReachOutModalStyles';

const ExternalSearchReachOutModalComponent = ({
  searchFilters,
  barredLocations,
  practiceAreas,
  user,
}) => {
  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());
  const [showThankYou, setShowThankYou] = useState(false);
  const [hasBlockedEmail, setHasBlockedEmail] = useState(false);
  const isAuthed = isAFCUser(user);

  const closeThankYou = () => setShowThankYou(false);

  const getBarredLocations = selectedBarredLocations => {
    const locationNames = selectedBarredLocations
      ?.map(location => {
        return barredLocations.find(bl => bl.id === location)?.name;
      })
      .filter(Boolean);

    return locationNames?.length > 0 ? locationNames : undefined;
  };

  const getPracticeAreas = selectedPracticeAreas => {
    const areaNames = selectedPracticeAreas
      ?.map(area => practiceAreas.find(pa => pa.id === area)?.name)
      .filter(Boolean);

    return areaNames?.length > 0 ? areaNames : undefined;
  };

  const getIndustries = selectedIndustries => {
    const industryNames = selectedIndustries
      ?.map(industry => taxonomy.industries[industry].displayValue)
      .filter(Boolean);

    return industryNames?.length > 0 ? industryNames : undefined;
  };

  const getCountriesFromCountryCodes = countryCodes => {
    const countries = countryCodes
      ?.map(countryCode => CountryCodesConst.CountryCodes[countryCode])
      .filter(Boolean);

    return countries?.length > 0 ? countries : undefined;
  };

  const getFilterData = () => {
    const data = {
      barredLocations: getBarredLocations(searchFilters?.barredLocations || []),
      practiceAreas: getPracticeAreas(searchFilters?.practiceAreaId || []),
      industries: getIndustries(searchFilters?.industries || []),
      yearsOfExperience: searchFilters?.yearsOfExperience,
      countries: getCountriesFromCountryCodes(
        searchFilters?.addressCountryCode || []
      ),
      state: searchFilters?.state,
      profession: searchFilters?.profession,
      weeklyAvailability: searchFilters?.weeklyAvailability,
    };

    const isEmpty = Object.values(data).every(x => x === undefined);

    return isEmpty ? null : data;
  };

  const renderLabel = (text, isRequired = true) => (
    <Label>
      {text} {isRequired && <TSROMColoredText>*</TSROMColoredText>}
    </Label>
  );

  return (
    <FormStateTreatment
      isModalState
      name="EXTERNAL_SEARCH_REACH_OUT_TREATMENT"
      formStore={FormExternalSearchReachOutModalStore}
      onSubmit={result => {
        if (result?.errorType === 'BLOCKED_EMAIL') {
          setHasBlockedEmail(true);
        } else {
          FormExternalSearchReachOutModalStore.clearState(result);
          setShowThankYou(true);
        }
      }}
      renderNonLoadedView={() =>
        showThankYou && (
          <Modal size="confirm">
            <ModalHeader onClose={closeThankYou}>We're on it</ModalHeader>
            <ModalSection>
              We'll get your request to the right person at Axiom. You can
              expect to hear back from us soon.
            </ModalSection>
            <ModalFooter>
              <Button name="got_it" onClick={closeThankYou}>
                Got it!
              </Button>
            </ModalFooter>
          </Modal>
        )
      }
      renderLoadedView={({ formData, fireSubmit, fireCancel }) => (
        <Modal name="REACH_OUT_FORM">
          <ModalHeader onClose={fireCancel}>More profiles please</ModalHeader>
          <ModalSection>
            <Layout position="center">
              <Gutter bottom="16px">
                <img width={100} alt="more-people" src={MoreProfiles} />
              </Gutter>
            </Layout>
            <Gutter bottom="16px">
              <Paragraph>
                Axiom can help find exactly what you're looking for. How can we
                reach you?
              </Paragraph>
            </Gutter>
            <Grid stackable>
              <GridRow gutterBottom="16px">
                <GridColumn width={6}>
                  <Input
                    label={renderLabel('First Name')}
                    name="firstName"
                    hideCharCount
                    disabled={isAuthed}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Input
                    label={renderLabel('Last Name')}
                    name="lastName"
                    hideCharCount
                    disabled={isAuthed}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={6}>
                  <Input
                    label={renderLabel('Email')}
                    name="email"
                    onFocus={() => setHasBlockedEmail(false)}
                    hideCharCount
                    disabled={isAuthed}
                  />
                  {hasBlockedEmail && (
                    <TSROMCustomError
                      data-test={DataTestUtil.format('blocked_email_error')}
                    >
                      Please enter a business email.
                    </TSROMCustomError>
                  )}
                </GridColumn>
                <GridColumn width={6}>
                  <Input
                    label={renderLabel('Phone Number', false)}
                    name="phoneNumber"
                    hideCharCount
                  />
                </GridColumn>
              </GridRow>
              <Gutter bottom="8px" />
              <CreateAccountTosPrivacyNotice user={user} withMakeAccount />
            </Grid>
          </ModalSection>
          <ModalFooter>
            <Button variation="outline" onClick={fireCancel} name="cancel">
              Cancel
            </Button>
            <Button
              onClick={() => {
                formData.searchFilters = getFilterData();
                fireSubmit();
              }}
              name="submit"
            >
              {isAuthed ? 'Submit' : 'Agree and Submit'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    />
  );
};

ExternalSearchReachOutModalComponent.propTypes = {
  searchFilters: FormTalentSearchFiltersStore.getSelectedFiltersShape(),
  barredLocations: PreloadedBarredLocationsStore.getDataShape().isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
  user: PreloadedUserStore.getDataShape(),
};

ExternalSearchReachOutModalComponent.defaultProps = {
  searchFilters: null,
  user: null,
};

export const ExternalSearchReachOutModal = connect(state => ({
  searchFilters: FormTalentSearchFiltersStore.selectFilterData(state),
  barredLocations: PreloadedBarredLocationsStore.selectData(state),
  practiceAreas: PreloadedPracticeAreasStore.selectData(state),
  user: PreloadedUserStore.selectData(state),
}))(ExternalSearchReachOutModalComponent);
