import { Api } from '@axiom/ui';
import { Language } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class LanguagesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readLanguages() {
    return super.read<{ data: Array<Language> }>({
      endpoint: '/languages',
      method: 'GET',
    });
  }
}

export const LanguagesApi = new LanguagesApiClass();
