import React from 'react';
import { CondensedLarge, Gutter, Layout } from '@axiom/ui';
import { Plural } from '@lingui/macro';
import { arrayOf } from 'prop-types';

import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';
import { FormExternalSearchReachOutModalStore } from '../../stores/form-external-search-reach-out-modal-store';
import { WindowUtil } from '../../utils/window-util';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { SearchStore } from '../../stores/search-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { searchResultsShape } from '../../models/search';

import { TalentSearchSeeMoreCard } from './TalentSearchSeeMoreCard';
import { TalentSearchPills } from './TalentSearchPills';
import { TalentSearchEmpty } from './TalentSearchEmpty';
import { TalentSearchPagination } from './TalentSearchPagination';
import { TalentSearchCard } from './TalentSearchCard';
import { TalentSearchSortBy } from './TalentSearchSortBy';

export const TalentSearchFindTalent = ({
  candidates,
  grid,
  practiceAreas,
  selectedFilter,
  user,
}) => {
  const candidatesExist = candidates.length > 0;

  return (
    <>
      <TalentSearchPills filters={selectedFilter} />
      <Gutter bottom="16px">
        <Layout position="space-between middle">
          <CondensedLarge name="total_profile_count">
            <Plural
              value={grid?.meta?.serverMeta?.resultCount || 0}
              one="# profile"
              other="# profiles"
            />
          </CondensedLarge>
          <TalentSearchSortBy
            filters={selectedFilter}
            disabled={!candidatesExist}
          />
        </Layout>
      </Gutter>
      {candidatesExist ? (
        <>
          {candidates.map(c => (
            <Gutter bottom="16px" key={c.id}>
              <TalentSearchCard
                candidate={c}
                practiceAreas={practiceAreas}
                user={user}
              />
            </Gutter>
          ))}
          {candidates.length % 12 === 0 ? null : (
            <Gutter bottom="16px">
              <TalentSearchSeeMoreCard
                user={user}
                activeFilters={grid?.meta?.searchConfig?.filters}
              />
            </Gutter>
          )}
        </>
      ) : (
        <Gutter bottom="16px">
          <TalentSearchEmpty
            onClick={view => {
              WindowUtil.setUrlQuery({ view });
            }}
            activeFilters={grid?.meta?.searchConfig?.filters}
            isHomeTabSelected
            handleClearFiltersClick={() => {
              FormTalentSearchFiltersStore.submit();
            }}
            handleReachOutClick={() => {
              FormExternalSearchReachOutModalStore.load(user);
            }}
          />
        </Gutter>
      )}
      <Layout position="right">
        <Gutter bottom="16px">
          <TalentSearchPagination
            storeData={grid}
            location="bottom"
            user={user}
          />
        </Gutter>
      </Layout>
    </>
  );
};

TalentSearchFindTalent.propTypes = {
  candidates: arrayOf(searchResultsShape).isRequired,
  grid: SearchStore.getDataShape().isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
  selectedFilter: FormTalentSearchFiltersStore.getDataShape().isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
};
