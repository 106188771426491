/**
 * USAGE
 * Used if you just need to do a one-off action/select
 * */

export default class GenericStateHelper {
  constructor(namespace, eventName) {
    this.namespace = namespace;
    this.eventName = eventName;
  }

  getActionName() {
    return `${this.namespace}_${this.eventName}`;
  }

  actionSet(payload) {
    return dispatch =>
      dispatch({ type: `${this.namespace}_${this.eventName}`, payload });
  }

  select(state) {
    return { ...state?.[`${this.namespace}`] };
  }
}
