import { SchemaDate, SchemaLocation } from '@axiom/types';
import { z } from 'zod';
import { IndustryValue, PositionsConst } from '@axiom/const';
import { DateUtil } from '@axiom/ui';
import moment from 'moment';

export const licensedLawyerValues = {
  Myself: 'Myself',
  SomeoneElse: 'Someone else',
} as const;

export const worksiteValues = {
  [PositionsConst.Worksites.Remote]: 'No, remote is fine',
  [PositionsConst.Worksites.OnsiteRemote]: 'Yes, periodically in our office',
  [PositionsConst.Worksites.Onsite]: 'Yes, full time in our office',
} as const;

export const occupationTypeValues = {
  Lawyer: 'Lawyer',
  'Legal Support': 'Legal support',
} as const;

export const levelOfExperienceValues = {
  ZeroYears: '0+ years',
  FourYears: '4+ years',
  EightYears: '8+ years',
  TwelveYears: '12+ years',
  FifteenYears: '15+ years',
} as const;

export const positionReservedTypeValues = {
  Reserved: 'Reserved',
  'Not Reserved': 'Not Reserved',
} as const;

export function checkDateRange(
  context: z.RefinementCtx,
  errorPath: string,
  startDate: string,
  endDate: string
) {
  if (!DateUtil.isDateAfterDate(endDate, moment(startDate).toDate())) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Must be after start date',
      path: [errorPath],
    });
  }
}

export function checkHourlyRate(
  context: z.RefinementCtx,
  errorPath: string,
  minHourlyRate: string,
  maxHourlyRate: string
) {
  if (maxHourlyRate < minHourlyRate) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Must be more than minimum hourly rate',
      path: [errorPath],
    });
  }
}

export const projectNeedsSchema = z.object({
  billingUnitsPerWeek: z
    .number()
    .positive()
    .int('Must be a whole hour')
    .gte(1, { message: 'Must be between 1 and 40 hours' })
    .lte(40, { message: 'Must be between 1 and 40 hours' }),
  companyLocation: SchemaLocation.nullable().optional(),
  description: z.string().max(150).nullable().optional(),
  endDate: SchemaDate,
  focusArea: z.string(),
  generalSkills: z.array(z.string()).nullish().optional(),
  industry: z
    .enum(Object.keys(IndustryValue) as NonEmptyArray<IndustryValue>)
    .nullable()
    .optional(),
  language: z.string().nullable().optional(),
  legalTechSkills: z.array(z.string()).nullish().optional(),
  levelOfExperience: z.enum(
    Object.keys(
      levelOfExperienceValues
    ) as NonEmptyArray<LevelOfExperienceValue>
  ),
  licensedLawyer: z.enum(
    Object.keys(licensedLawyerValues) as NonEmptyArray<LicensedLawyerValue>
  ),
  licensedLawyerState: z.string(),
  maxBudgetPerHours: z
    .number({
      invalid_type_error: 'Enter a number over 100$',
    })
    .positive()
    .gte(100, { message: 'Enter a number over 100$' }),
  minBudgetPerHours: z
    .number({
      invalid_type_error: 'Enter a number over 0$',
    })
    .positive()
    .gte(1, { message: 'Enter a number over 0$' }),
  occupationType: z.enum(
    Object.keys(occupationTypeValues) as NonEmptyArray<OccupationTypeValue>
  ),
  otherFocusAreas: z.array(z.string()).nullish().optional(),
  reservedType: z.enum(
    Object.keys(
      positionReservedTypeValues
    ) as NonEmptyArray<PositionReservedValue>
  ),
  startDate: SchemaDate.superRefine((value, ctx) => {
    if (DateUtil.isDateAfterDate(moment(), moment(value).toDate())) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Must be today or after',
      });
    }
  }),
  worksite: z.enum(Object.keys(worksiteValues) as NonEmptyArray<string>),
});

export type ProjectNeeds = z.infer<typeof projectNeedsSchema>;

export type ProjectNeedsComplete = ProjectNeeds & {
  legalSkills: string[];
};

export type ProjectNeedsFormType = z.infer<typeof projectNeedsSchema>;

type LicensedLawyerValue =
  (typeof licensedLawyerValues)[keyof typeof licensedLawyerValues];
export type WorkSiteValue =
  (typeof worksiteValues)[keyof typeof worksiteValues];
type OccupationTypeValue =
  (typeof occupationTypeValues)[keyof typeof occupationTypeValues];
type LevelOfExperienceValue =
  (typeof levelOfExperienceValues)[keyof typeof levelOfExperienceValues];
type PositionReservedValue =
  (typeof positionReservedTypeValues)[keyof typeof positionReservedTypeValues];
export type ProjectNeedsForm = z.infer<typeof projectNeedsSchema>;
