import React from 'react';
import { connect } from 'react-redux';
import { DSCandidate, PDFExport, SubmissionUtil } from '@axiom/ui';
import { oneOfType, func, shape, instanceOf, Element } from 'prop-types';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { SubmissionStore } from '../../stores/submission-store';

const SubmissionComponent = ({ submissionState, pdfRoot }) => {
  return (
    <StoreStateTreatment
      allowOverflow
      name="ACCOUNT_SUBMISSIONS_PAGE_SUBMISSION_BODY"
      storeState={submissionState}
      renderLoadedView={({ data: submission }) => {
        const { candidates, ...opportunity } = submission;

        return (
          <PDFExport
            fileName={SubmissionUtil.getSubmissionFileName(submission)}
            ref={pdfRoot}
          >
            {candidates.map(candidate => (
              <div key={candidate.id}>
                <span id={`c-${candidate.id}`} key={candidate.id} />
                <DSCandidate
                  barredLocations={candidate.barredLocations}
                  candidate={candidate}
                  certifications={candidate.certifications}
                  currency={opportunity.currency}
                  degrees={candidate.degrees}
                  experiences={candidate.submissionExperiences}
                  languages={candidate.languages}
                />
              </div>
            ))}
          </PDFExport>
        );
      }}
    />
  );
};

SubmissionComponent.propTypes = {
  submissionState: SubmissionStore.getDataShape().isRequired,
  pdfRoot: oneOfType([func, shape({ current: instanceOf(Element) })])
    .isRequired,
};

export const Submission = connect(state => ({
  submissionState: SubmissionStore.select(state),
}))(SubmissionComponent);
