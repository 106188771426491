import React from 'react';
import {
  Carousel,
  LargeHeader,
  Gutter,
  Grid,
  GridRow,
  GridColumn,
} from '@axiom/ui';

import { useCandidateProfilesData } from '../../hooks/useCandidateProfilesData';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { DiscoverTalentEndCard } from '../DiscoverTalentEndCard/DiscoverTalentEndCard';

import { SavedTalentCarouselCard } from './SavedTalentCarouselCard';
import { SavedTalentCarouselEmptyStateCard } from './SavedTalentCarouselEmptyStateCard';

export const SavedTalentCarousel = () => {
  const { account, user } = useUserAccountData();
  const { savedCandidates } = useCandidateProfilesData(account);

  const buildChildren = () => {
    const kids = savedCandidates.map(candidate => {
      return (
        <SavedTalentCarouselCard
          user={user}
          industryValue={account.industryValue}
          candidate={candidate}
          key={`${account.id}-${candidate.id}`}
        />
      );
    });
    kids.push(<DiscoverTalentEndCard key="saved-endCard" account={account} />);
    return kids;
  };

  return (
    <>
      <LargeHeader name="SAVED_TALENT_HEADER">
        Your Saved talent{' '}
        {savedCandidates.length > 0 ? `(${savedCandidates.length})` : ''}
      </LargeHeader>
      <Gutter bottom="24px" />
      {savedCandidates.length > 0 ? (
        <Carousel name="SAVED_TALENT_CAROUSEL">{buildChildren()}</Carousel>
      ) : (
        <Grid name="SAVED_TALENT_EMPTY_STATE" stackable>
          <GridRow stretched>
            <GridColumn largeScreenWidth={8} tabletWidth={12} mobileWidth={12}>
              <SavedTalentCarouselEmptyStateCard />
              <Gutter only="smallScreen" bottom="16px" />
            </GridColumn>
            <GridColumn largeScreenWidth={4} tabletWidth={12} mobileWidth={12}>
              <DiscoverTalentEndCard account={account} />
            </GridColumn>
          </GridRow>
        </Grid>
      )}
    </>
  );
};
