import React from 'react';
import { oneOfType, string, number, shape, bool } from 'prop-types';
import { Flashy, Icon, Layout, Divider, Well } from '@axiom/ui';

import { isAFCUser } from '../../utils/roles';

const formatRate = rate =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'usd',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(rate);

export const TalentRateAndAvailabilityRow = ({ candidate, user }) => {
  // Rate will eventually be added back in.
  const hasRate = false;

  const weeklyAvailabilityLabel = {
    fullTime: 'Full-time',
    partTime: 'Part-time',
    unavailable: 'Unavailable',
  }[candidate.weeklyAvailabilityLabel];

  return (
    <Well>
      <Layout horizontalGutter="12px" wrap>
        <Layout position="middle" horizontalGutter="8px" inline>
          <Icon name="clock-filled" />
          <Layout horizontalGutter="8px" inline position="middle">
            <Flashy bold>Current Availability</Flashy>
            {isAFCUser(user) && !Number.isNaN(candidate.weeklyAvailability) ? (
              <span data-test="WEEKLY_AVAILABILITY">
                {candidate.weeklyAvailability} hours
              </span>
            ) : (
              <span data-test="WEEKLY_AVAILABILITY">
                {weeklyAvailabilityLabel}
              </span>
            )}
          </Layout>
        </Layout>

        {hasRate && (
          <Layout horizontalGutter="8px" position="top" inline>
            <Layout position="middle" horizontalGutter="8px" inline>
              <Icon name="dollar-filled" />
              <Flashy bold>Rate</Flashy>
            </Layout>
            <span>
              <Divider>
                <span>
                  Hourly:{' '}
                  <span data-test="DISPLAY_LOW_HOURLY_RATE">
                    {formatRate(candidate.displayLowHourlyRate)}
                  </span>
                  -
                  <span data-test="DISPLAY_HIGH_HOURLY_RATE">
                    {formatRate(candidate.displayHighHourlyRate)}
                  </span>
                  /hr{' '}
                </span>
                <span>
                  {' '}
                  Full-time:{' '}
                  <span data-test="DISPLAY_WEEKLY_RATE">
                    {formatRate(candidate.displayWeeklyRate)}
                  </span>
                  /wk{' '}
                </span>
              </Divider>
            </span>
          </Layout>
        )}
      </Layout>
    </Well>
  );
};

TalentRateAndAvailabilityRow.propTypes = {
  candidate: shape({
    hasRate: bool,
    displayLowHourlyRate: oneOfType([number, string]),
    displayHighHourlyRate: oneOfType([number, string]),
    displayWeeklyRate: oneOfType([number, string]),
    weeklyAvailability: oneOfType([number, string]),
  }).isRequired,
  user: shape({}),
};

TalentRateAndAvailabilityRow.defaultProps = {
  user: null,
};
