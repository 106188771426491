import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import styled, { css, ThemeProvider } from 'styled-components';
import {
  Blocker,
  ConsumeWarningsUtil,
  DesignSysEnvUtil,
  ErrorBoundary,
  I18nProvider,
  LightTheme,
  LoggerUtil,
  MediaQueryUtil,
  ServiceWorkerProvider,
  Sidedrawer,
  Suspense,
  Toasts,
} from '@axiom/ui';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { EnvUtil } from './utils/env-util';
import { PendoInitializer } from './components/PendoInitializer/PendoInitializer';
import enLocale from './locales/en/messages';
import deLocale from './locales/de/messages';
import laLocale from './locales/la/messages';
import themes from './themes';
import { AppRouter } from './Router';
import { initializeStore } from './lib/store-manager';
import '@progress/kendo-theme-default/dist/all.css';
import '@axiom/ui/src/public/static/kendo-overrides.css';
// eslint-disable-next-line import/no-unresolved
import 'semantic-ui-less/semantic.less';
import '@axiom/ui/src/public/static/bootstrap.min.css';
import '@axiom/ui/src/public/static/main.css';
import '@axiom/ui/src/public/static/main-internal.css';
import '@axiom/ui/src/public/static/css-helpers.css';
import { ErrorBoundaryLayout } from './layouts/ErrorBoundaryLayout/ErrorBoundaryLayout';

/**
 * Temp disable until we can get to this ticket
 * APCORE-2484
 */
if (process.env.NODE_ENV !== 'production') {
  ConsumeWarningsUtil.consumePropWarnings();
}

const AppWrapper = styled.div`
  html {
    font-size: 1rem;
  }

  @media print {
    html {
      font-size: 1.25rem;
      padding-left: 0;
      padding-right: 0;
    }
    .row(.top-logo-header) {
      page-break-inside: avoid;
    }
  }

  hr {
    margin-top: 8px;
    margin-bottom: 0;
    background-color: rgb(0, 0, 0);
  }

  @page {
    margin-left: -50mm;
    margin-right: -50mm;
  }

  // .fluid-padding {
  //   max-width: 1980px;
  //   margin: auto;
  // }
  //
  // .fluid-padding > div:first-child,
  // .fluid-padding > div:last-child {
  //   padding-left: 8px !important;
  //   padding-right: 8px !important;
  // }
  //
  // .popover {
  //   border-radius: 0.5rem !important;
  //   padding: 8px;
  // }
  //
  // .no-margin {
  //   margin: 0px !important;
  // }
  //
  // @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  //   .hide-ie {
  //     display: none !important;
  //   }
  // }
  //
  // ${MediaQueryUtil.smallScreenOnly(css`
    //   .panel {
    //     margin-bottom: 30px !important;
    //   }
    //   .fluid-padding > div {
    //     padding-left: 0 !important;
    //     padding-right: 0 !important;
    //   }
    //   .nav {
    //     display: block !important;
    //     text-align: left;
    //     padding: 1.2rem;
    //   }
    //   .nav li {
    //     max-width: 180px;
    //   }
    //   .notification-counter {
    //     display: none !important;
    //   }
    //
  `)}

  /**
    Temp solution till we tear out bootstrap and replace nav
   */
  @media screen and (min-width: 1024px) {
    .nav {
      padding: 0;
      display: flex !important;
      align-items: center;
    }
  }

  /**
    Temp solution till we tear out bootstrap and replace nav
   */
  @media (max-width: 1023px) {
    .axiom-nav {
      height: 100vh !important;
    }
  }

  .axiom-nav a {
    margin-right: 20px;
  }
`;

DesignSysEnvUtil.initialize({
  COOKIE_DOMAIN: EnvUtil.cookieDomain,
  GOOGLE_MAPS_API_URL: EnvUtil.googleMapsApiUrl,
  ENABLE_SUGGESTIONS: EnvUtil.enableSuggestions,
  S3_PUBLIC_BASE_URL: EnvUtil.publicBaseUrlS3,
  S3_PUBLIC_FILE_BUCKET: EnvUtil.publicS3Bucket,
  ENABLE_CALENDAR_EVENTS_TODAY: EnvUtil.enableCalendarEventsToday,
});

if (EnvUtil.enableLogging) {
  LoggerUtil.initialize(
    EnvUtil.logglyClientId,
    'client',
    EnvUtil.environmentName
  );
}

const store = initializeStore();

const Bootstrap = () => {
  return (
    <AppWrapper>
      <PendoInitializer />
      <I18nProvider
        locales={{
          en: enLocale,
          de: deLocale,
          la: laLocale,
        }}
      >
        <Provider store={store}>
          <ThemeProvider theme={themes.main}>
            <BrowserRouter future={{ v7_startTransition: true }}>
              <ErrorBoundary errorLayout={<ErrorBoundaryLayout />}>
                <Blocker>
                  <Toasts />
                  <Suspense>
                    <LightTheme backgroundName="none">
                      <Sidedrawer>
                        <ServiceWorkerProvider />
                        <AppRouter />
                      </Sidedrawer>
                    </LightTheme>
                  </Suspense>
                </Blocker>
              </ErrorBoundary>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </I18nProvider>
    </AppWrapper>
  );
};

const root = createRoot(document.querySelector('#root'));
root.render(<Bootstrap />);
