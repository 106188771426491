import React from 'react';
import { Candidate, CandidateOpportunity, Submission } from '@axiom/validation';
import {
  backgroundColorNames,
  Badge,
  Gutter,
  ParagraphHeader,
  Tile,
  Tiles,
  useBreakpoint,
  BadgeBackgroundType,
} from '@axiom/ui';
import { useNavigate } from 'react-router-dom';

import { ClientEngagementsEngagementTalentTile } from './ClientEngagementsEngagementTalentTile';

export type ClientEngagementsEngagementTalentListProps = {
  hasMultipleRoles?: boolean;
  candidateOpportunities: Array<CandidateOpportunity>;
  selectedCandidateId?: Candidate['id'];
  submission: Submission;
  title: string;
};

const BADGE_VALUES = {
  AXIOM_PICK: 'AXIOM PICK',
  SELF_SUBMITTED: 'SELF-SUBMITTED',
};

const badgeMap = new Map([
  [BADGE_VALUES.AXIOM_PICK, backgroundColorNames.lightOrange],
  [BADGE_VALUES.SELF_SUBMITTED, backgroundColorNames.lightBlue],
]);

const setBadgeColor = (badge: string): BadgeBackgroundType | null => {
  if (!badge) return null;

  const key = badge.toUpperCase();

  if (badgeMap.has(key)) {
    return badgeMap.get(key);
  }

  return 'gray';
};

export const ClientEngagementsEngagementTalentList = ({
  hasMultipleRoles = false,
  candidateOpportunities,
  selectedCandidateId,
  submission,
  title,
}: ClientEngagementsEngagementTalentListProps) => {
  const navigate = useNavigate();
  const { isLargeScreen } = useBreakpoint();

  return (
    <>
      {hasMultipleRoles && (
        <Gutter bottom="8px">
          <ParagraphHeader name="role-title">{title}</ParagraphHeader>
        </Gutter>
      )}
      <Gutter bottom="16px" name={`${title} item`}>
        <Tiles columnWidth={1} name="candidate-tiles">
          {candidateOpportunities.map(candidateOpp => {
            const badgeBG = setBadgeColor(candidateOpp.badge);

            return (
              <Tile
                selected={candidateOpp.candidate.id === selectedCandidateId}
                key={candidateOpp.candidate.id}
                onClick={() => {
                  navigate(`./${candidateOpp.candidate.id}`);
                }}
                name={candidateOpp.candidate.id}
                Badge={
                  candidateOpp.badge && (
                    <Badge flush="right" background={badgeBG} name="badge">
                      {candidateOpp.badge}
                    </Badge>
                  )
                }
              >
                <Gutter
                  top={isLargeScreen ? '8px' : null}
                  bottom="8px"
                  left="8px"
                >
                  <ClientEngagementsEngagementTalentTile
                    candidateOpp={candidateOpp}
                    submission={submission}
                  />
                </Gutter>
              </Tile>
            );
          })}
        </Tiles>
      </Gutter>
    </>
  );
};
