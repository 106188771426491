import React from 'react';
import { IndustryValue } from '@axiom/const';
import { SearchCandidate } from '@axiom/validation';
import { CondensedLarge, Icon, Layout } from '@axiom/ui';

export const DiscoverTalentCarouselEngagements = ({
  candidate,
}: {
  candidate: SearchCandidate;
}) => {
  let displayData = null;
  if (candidate.axiomExperienceCount) {
    displayData = `${candidate.axiomExperienceCount} Axiom engagement${
      candidate.axiomExperienceCount > 1 ? 's' : ''
    }`;
  }

  if (!displayData && candidate.industries?.length) {
    const lawFirms = candidate.industries.filter(e => {
      return e.industryValue === IndustryValue.LawFirms;
    });
    const lawFirmYoE =
      lawFirms.length > 0
        ? Math.floor(
            lawFirms.reduce((crnt, data) => {
              crnt += Number.parseFloat(data.yearsOfExperience);
              return crnt;
            }, 0)
          )
        : -1;

    if (lawFirmYoE >= 1) {
      displayData = `${lawFirmYoE} Year${
        lawFirmYoE > 1 ? 's' : ''
      } in Law Firm`;
    } else if (lawFirmYoE === 0) {
      displayData = 'Less than 1 Year in Law Firm';
    }
  }

  if (!displayData && candidate.lawDegreeSchools?.length) {
    const jd =
      candidate.lawDegreeSchools.find(data => data.degree === 'JD') || null;

    if (jd) {
      displayData = jd.institution;
    }
  }

  return displayData ? (
    <Layout horizontalGutter="16px">
      <Icon name="checkmark" />
      <CondensedLarge name="DISCOVER_HIGHLIGHT_ENGAGEMENT">
        {displayData}
      </CondensedLarge>
    </Layout>
  ) : null;
};
