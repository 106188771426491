import React from 'react';
import { Grid, GridColumn, GridRow, Gutter } from '@axiom/ui';
import { CandidateOpportunitiesConst } from '@axiom/const';
import { Trans } from '@lingui/macro';

import { AxiomTeam } from '../AxiomTeam/AxiomTeam';
import { AccountCandidatesStore } from '../../stores/account-candidates-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { CandidateAccountShape } from '../../models/candidate';

import {
  BCCandidatesCount,
  BCCandidatesCountWrapper,
  BCStickySidebar,
} from './BenchCandidatesStyles';
import { BenchCandidatesItemGrid } from './BenchCandidatesItemGrid';
import { BCSortFilters } from './BCSortFilters';
import { BenchCandidatesEmpty } from './BenchCandidatesEmpty';

export const BenchCandidatesItems = ({
  accountCandidates,
  account,
  practiceAreas,
  user,
}) => {
  const counts = accountCandidates.data.reduce(
    (acc, candidate) => {
      if (
        candidate.accountCandidateStatus ===
        CandidateOpportunitiesConst.CandidateStatuses.Engaged
      ) {
        acc.engagedCount += 1;
      } else if (
        candidate.accountCandidateStatus ===
        CandidateOpportunitiesConst.CandidateStatuses.Completed
      ) {
        acc.completedCount += 1;
      }
      return acc;
    },
    { engagedCount: 0, completedCount: 0 }
  );

  return (
    <Grid>
      <GridRow>
        <GridColumn smallScreenWidth={12} largeScreenWidth={9}>
          <BCCandidatesCountWrapper bottom="1.875rem">
            <div>
              <BCCandidatesCount name="CURRENTLY_ENGAGED_COUNT" bold>
                <Trans>Currently engaged: {counts.engagedCount}</Trans>
              </BCCandidatesCount>
              <Gutter as="span" right="5px" left="5px">
                |
              </Gutter>
              <BCCandidatesCount name="PREV_ENGAGED_COUNT" bold>
                <Trans>Previously engaged: {counts.completedCount}</Trans>
              </BCCandidatesCount>
            </div>
          </BCCandidatesCountWrapper>

          <BCSortFilters
            accountCandidates={accountCandidates}
            practiceAreas={practiceAreas}
            user={user}
          />

          {accountCandidates.data.length > 0 && user ? (
            <BenchCandidatesItemGrid
              accountCandidates={accountCandidates}
              user={user}
              accountId={account.id}
              practiceAreas={practiceAreas}
            />
          ) : (
            <BenchCandidatesEmpty
              noFilterData={accountCandidates.noFilterData}
              user={user}
            />
          )}
        </GridColumn>
        <GridColumn largeScreenWidth={3} only="largeScreen">
          <BCStickySidebar>
            <AxiomTeam
              accountName={account?.calculatedName}
              ownerUser={account?.ownerUser}
              cxPartner={account?.cxPartner}
            />
          </BCStickySidebar>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

BenchCandidatesItems.defaultProps = {
  account: null,
};

BenchCandidatesItems.propTypes = {
  accountCandidates: AccountCandidatesStore.getDataShape().isRequired,
  account: CandidateAccountShape,
  user: PreloadedUserStore.getDataShape().isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
};
