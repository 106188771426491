/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { number } from 'prop-types';
import styled from 'styled-components';

import { ComponentShape } from '../../utils/prop-type-util';

export const AspectRatioContainer = styled.div`
  position: relative;
`;
export const AspectRatioDummy = styled(({ aspectRatio, ...props }) => (
  <div data-test="ASPECT_RATIO_DUMMY" {...props} />
))`
  padding-bottom: ${({ aspectRatio }) => `${100 / aspectRatio}%`};
`;
export const AspectRatioBody = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const AspectRatio = ({ aspectRatio, children, ...props }) => {
  return (
    <AspectRatioContainer {...props}>
      <AspectRatioDummy aspectRatio={aspectRatio} />
      <AspectRatioBody data-test="ASPECT_RATIO_ITEM">
        {children}
      </AspectRatioBody>
    </AspectRatioContainer>
  );
};

AspectRatio.defaultProps = {
  aspectRatio: 1,
  children: null,
};
AspectRatio.propTypes = {
  aspectRatio: number,
  children: ComponentShape,
};
