import React from 'react';
import { Grid, GridRow, GridColumn } from '@axiom/ui';

import { EngagementsCarousel } from '../EngagementsCarousel/EngagementsCarousel';
import { DiscoverTalentCarousel } from '../DiscoverTalentCarousel/DiscoverTalentCarousel';
import { SavedTalentCarousel } from '../SavedTalentCarousel/SavedTalentCarousel';
import { BrowseTalent } from '../BrowseTalent/BrowseTalent';

export const MyAxiomDashboard = () => {
  return (
    <Grid name="DASHBOARD">
      <GridRow gutterBottom="24px">
        <GridColumn>
          <EngagementsCarousel />
        </GridColumn>
      </GridRow>

      <GridRow gutterBottom="24px">
        <GridColumn>
          <DiscoverTalentCarousel />
        </GridColumn>
      </GridRow>

      <GridRow gutterBottom="24px">
        <GridColumn>
          <SavedTalentCarousel />
        </GridColumn>
      </GridRow>

      <BrowseTalent />
    </Grid>
  );
};
