import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Gutter, MediaQueryUtil, Text } from '@axiom/ui';

import { SingleSelect } from '../FormElements/SingleSelect/SingleSelect';
import { Input } from '../FormElements/Input/Input';
import { PALETTE } from '../../themes/constants';

export const BCAvailabilityContainer = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.secondary};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0 8px 16px;
`;

export const BCAvailabilityLeft = styled.div`
  padding: 0 16px 8px 0;

  b {
    display: block;
  }
`;

export const BCAvailabilityRight = styled.div`
  padding: 0 16px 8px 0;
  text-align: right;
`;

export const BCCandidatesCount = styled(Text)`
  display: inline-block;
  white-space: nowrap;
`;

export const BCCandidatesCountWrapper = styled(Gutter)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  button {
    white-space: nowrap;
  }
`;

export const BCCardButtons = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  margin-top: 1rem;
  & > * {
    width: 100%;
  }
`;

const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;

  .profile-circle {
    flex: none;
  }

  .full-width {
    flex: 1 1 auto;
  }

  .roll-off-row {
    display: inline-block;

    .info-icon {
      display: inline;
      padding-left: 7px;

      button {
        transform: translateY(0.2rem);
      }
    }
  }
`;

// eslint-disable-next-line react/prop-types
export const BCCardHeader = forwardRef(({ children, ...props }, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledCardHeader {...props} ref={ref}>
    {children}
  </StyledCardHeader>
));

export const BCEmptyBenchContainer = styled(Gutter)`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.neutrals.lightest};
  text-align: center;
`;

// TODO: Fix flexi card stuff in @axiom/ui
export const BCItemGridWrapper = styled.div`
  .flexi-card-body:first-child:not(:last-child),
  .flexi-card-body + .flexi-card-body:not(:nth-child(2)) {
    flex-grow: 0 !important;
  }

  .hidden-external-body,
  .hidden-external-buttons {
    background-color: ${({ theme }) => theme.neutrals.neutral20};
  }

  .hidden-external-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .hidden-external-header {
    background-color: ${PALETTE.grays.gray6};
  }
`;

export const BCStickySidebar = styled.div`
  position: sticky;
  top: 140px;
`;

export const BCSortFiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: -1rem;

  ${MediaQueryUtil.largeScreenOnly(css`
    flex-wrap: nowrap;
  `)}
`;

export const BCSortFilterItem = styled.div`
  margin-bottom: 1rem;
  padding-left: 1rem;
`;

export const BCFilterWrapper = styled(BCSortFilterItem)`
  flex: 0 1 100%;
  min-width: 13rem;
  order: 2;

  ${MediaQueryUtil.largeScreenOnly(css`
    flex: 1 1 auto;
    max-width: 18rem;
    order: initial;
  `)}
`;

export const BCKeywordWrapper = styled(BCSortFilterItem)`
  display: flex;
  align-items: flex-end;
  flex: 0 1 100%;
  order: 2;

  ${MediaQueryUtil.largeScreenOnly(css`
    max-width: 16.75rem;
    order: initial;
  `)}

  & > div {
    flex: 1;
  }

  & > svg {
    border-bottom: solid 1px #ccc;
    box-sizing: content-box;
    flex: none;
    padding-bottom: 3px;
    padding-right: 6px;
  }
`;

export const BCSortWrapper = styled(BCSortFilterItem)`
  flex: none;
  min-width: 14rem;

  ${MediaQueryUtil.largeScreenOnly(css`
    flex: 1 1 auto;
    max-width: 18rem;
    margin-left: auto;
  `)}
`;

export const BCTransparentSingleSelect = styled(SingleSelect)`
  .k-dropdown {
    .k-dropdown-wrap {
      background-color: transparent;

      .k-select {
        background-color: transparent;
      }
    }
  }
`;

export const BCKeywordInput = styled(Input)`
  width: 100%;
  max-width: 14.4375rem;
  height: 28.5px;
  display: flex;
  align-items: flex-end;

  input,
  input:focus {
    background-color: transparent;
    // hubspot style override
    min-height: 0;
  }

  && .flex-wrapper {
    display: none;
  }

  ${MediaQueryUtil.smallScreenOnly(css`
    width: 100%;
    max-width: none;
  `)}
`;
