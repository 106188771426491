import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import isUuid from 'is-uuid';
import {
  Grid,
  GridRow,
  GridColumn,
  Gutter,
  Tabs,
  Tab,
  Visible,
  Button,
  SidedrawerUtil,
} from '@axiom/ui';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { isEnvoyUser, isLoggedInUser } from '../../utils/roles';
import { WindowUtil } from '../../utils/window-util';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';
import { SearchStore } from '../../stores/search-store';
import { FavoriteCandidateStore } from '../../stores/favorite-candidate-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { FormExternalSearchReachOutModalStore } from '../../stores/form-external-search-reach-out-modal-store';
import { PreloadedSearchFiltersStore } from '../../stores/preloaded-search-filters-store';
import { localStorageUtil } from '../../utils/local-storage';
import { CandidateStore } from '../../stores/candidate-store';
import { ExternalCandidateStore } from '../../stores/external-candidate-store';
import { ShowPricingToggle } from '../ShowPricingToggle/ShowPricingToggle';
import { TalentSearchFilters } from '../TalentSearchFilters/TalentSearchFilters';

import { TalentSearchWrapper } from './TalentSearchStyles';
import { TalentSearchFindTalent } from './TalentSearchFindTalent';
import { TalentSearchFavorited } from './TalentSearchFavorited';
import { TalentSearchFilterSidedrawer } from './TalentSearchFilterSidedrawer';

const actionOnPageNumber = 5;
const actionNoShowDays = 60;

const TalentSearchComponent = ({
  practiceAreas,
  filters,
  gridState,
  user,
  selectedFilterState,
  favoriteCandidateState,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get('view');

  const [initLoaded, setInitLoaded] = useState(false);

  /**
   * 'actionTriggered' prevents the logic from running multiple times, redraw issues.
   *
   * This also keeps the action from multiple firings
   * if localStorage is disabled; per session
   * */
  const [actionTriggered, setActionTriggered] = useState(false);

  const { pageNumber } = useParams();
  const location = useLocation();

  // NOTE: industries were converted from UUIDs to enums
  // This is to handle an edge case where a user bookmarked a search with
  // a bad format
  useEffect(() => {
    const query = WindowUtil.getUrlQuery();

    if (query?.industries?.some(opt => isUuid.anyNonNil(opt))) {
      delete query.industries;

      WindowUtil.setUrlQuery(query);
    }
  }, []);

  useEffect(() => {
    SearchStore.loadContent(pageNumber || 1);

    if (!initLoaded) {
      setInitLoaded(!initLoaded);
      // clear individual candidate data when ALT is loaded to prevent bad
      // behavior with the url rewrite when going from: SPV 1 -> ALT -> SPV 2
      CandidateStore.clearState();
      ExternalCandidateStore.clearState();
    }
  }, [location]);

  useEffect(() => {
    FavoriteCandidateStore.load(isLoggedInUser(user) && user?.id);
  }, [user, view]);

  useEffect(() => {
    if (!gridState.isLoading && gridState.hasLoaded) {
      FormTalentSearchFiltersStore.load(filters, gridState, user);
    }
  }, [filters, gridState]);

  useEffect(() => {
    // Do the Thing if on page N and User hasn't already done the thing.
    if (!actionTriggered && +pageNumber === actionOnPageNumber) {
      // default is null if never seen before
      const lastDateViewed = localStorageUtil.paginationActionDate().get();
      let canViewInterstitial = true;

      if (
        lastDateViewed !== null &&
        moment(lastDateViewed).isAfter(moment().subtract(actionNoShowDays, 'd'))
      ) {
        // User has already seen Thing
        canViewInterstitial = false;
        setActionTriggered(true);
      }
      if (canViewInterstitial) {
        localStorageUtil
          .paginationActionDate()
          .set(moment().format('YYYY-MM-DD'));

        FormExternalSearchReachOutModalStore.load(user);

        setActionTriggered(true);
      }
    }
  }, [pageNumber, actionTriggered, actionOnPageNumber, actionNoShowDays]);

  const isHomeTabSelected = view !== 'saved';

  return (
    <StoreStateTreatment
      name="TALENT_SEARCH_TREATMENT"
      showInitialLoader={false}
      showRefetchLoader={false}
      storeState={[gridState, favoriteCandidateState, selectedFilterState]}
      renderLoadedView={({
        data: [grid, { idMap: favoritesMap, favorites }, selectedFilter],
      }) => {
        const candidates = grid.results.map(result => ({
          ...result,
          isFavorited: favoritesMap[result.id] ?? false,
        }));

        return (
          <Grid>
            <GridRow>
              <GridColumn mobileWidth={12} tabletWidth={4} largeScreenWidth={4}>
                <TalentSearchFilters disabled={!isHomeTabSelected} />
                {isEnvoyUser(user) && (
                  <Visible only={['tablet', 'largeScreen']}>
                    <ShowPricingToggle />
                  </Visible>
                )}
              </GridColumn>
              <GridColumn mobileWidth={12} tabletWidth={8} largeScreenWidth={8}>
                <TalentSearchWrapper>
                  <Tabs
                    startTab={isHomeTabSelected ? 'HOME_TAB' : 'FAVORITES_TAB'}
                  >
                    <Tab
                      name="HOME_TAB"
                      className="gtm-alt-tab-home"
                      label="Find Talent"
                      onClick={() => {
                        setSearchParams(() => {
                          const existing = WindowUtil.getUrlSearchParams();
                          existing.set('view', 'home');
                          return existing;
                        });
                      }}
                    >
                      <Gutter top="16px">
                        <Visible only="mobile">
                          <Button
                            className="gtm-talent-search-filters-open talent-search-filters-show-filters-button"
                            onClick={() =>
                              SidedrawerUtil.show({
                                direction: 'left',
                                only: 'mobile',
                                name: 'filters',
                                content: (
                                  <TalentSearchFilterSidedrawer
                                    disabled={!isHomeTabSelected}
                                  />
                                ),
                              })
                            }
                            pattern="secondary"
                            size="large"
                            fluid
                          >
                            Show filters
                          </Button>
                          <Gutter bottom="16px" />
                          {isEnvoyUser(user) && (
                            <Gutter bottom="16px">
                              <ShowPricingToggle />
                            </Gutter>
                          )}
                        </Visible>
                        <TalentSearchFindTalent
                          candidates={candidates}
                          grid={grid}
                          practiceAreas={practiceAreas}
                          selectedFilter={selectedFilter}
                          user={user}
                        />
                      </Gutter>
                    </Tab>
                    <Tab
                      name="FAVORITES_TAB"
                      className="gtm-alt-tab-saved"
                      countBadge={favorites?.length}
                      label={<span>Saved</span>}
                      onClick={() => {
                        setSearchParams(() => {
                          const existing = WindowUtil.getUrlSearchParams();
                          existing.set('view', 'saved');
                          return existing;
                        });
                      }}
                    >
                      <Gutter top="16px">
                        <TalentSearchFavorited
                          candidates={favorites}
                          grid={grid}
                          practiceAreas={practiceAreas}
                          user={user}
                        />
                      </Gutter>
                    </Tab>
                  </Tabs>
                </TalentSearchWrapper>
              </GridColumn>
            </GridRow>
          </Grid>
        );
      }}
    />
  );
};

TalentSearchComponent.propTypes = {
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
  selectedFilterState: FormTalentSearchFiltersStore.getStateShape().isRequired,
  favoriteCandidateState: FavoriteCandidateStore.getStateShape().isRequired,
  gridState: SearchStore.getStateShape().isRequired,
  user: PreloadedUserStore.getDataShape(),
  filters: PreloadedSearchFiltersStore.getDataShape().isRequired,
};

TalentSearchComponent.defaultProps = {
  user: {},
};

export const TalentSearch = connect(state => ({
  user: PreloadedUserStore.selectData(state),
  practiceAreas: PreloadedPracticeAreasStore.selectData(state),
  selectedFilterState: FormTalentSearchFiltersStore.select(state),
  favoriteCandidateState: FavoriteCandidateStore.select(state),
  gridState: SearchStore.select(state),
  filters: PreloadedSearchFiltersStore.selectData(state),
}))(TalentSearchComponent);
