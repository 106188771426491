import React from 'react';
import { Candidate, Experience, SearchCandidate } from '@axiom/validation';
import { IndustryValue } from '@axiom/const';
import { CompanyLogo, CondensedHeader, Divider, Gutter } from '@axiom/ui';

import { EnvUtil } from '../../utils/env-util';

export const DiscoverTalentCarouselExperiences = ({
  candidate,
}: {
  candidate: SearchCandidate;
}) => {
  const clientNamesSet = new Set();
  const clientLogosSet = new Set();
  const validExperiences =
    candidate.experiences?.filter(
      (exp: Candidate['experiences'][number]) =>
        !exp.isConfidential && exp.industryValue !== IndustryValue.LawFirms
    ) || [];

  const clientNames = validExperiences
    .reduce(
      (crnt, data: Experience) => {
        if (data.client && !clientNamesSet.has(data.client)) {
          clientNamesSet.add(data.client);
          crnt.push(data.client);
        }

        return crnt;
      },
      [] as Experience['client'][]
    )
    .slice(0, 5);

  const clientLogos = validExperiences
    .reduce(
      (crnt, data: Experience) => {
        if (data.clientDomain && !clientLogosSet.has(data.clientDomain)) {
          clientLogosSet.add(data.clientDomain);
          crnt.push(data.clientDomain);
        }

        return crnt;
      },
      [] as Experience['clientDomain'][]
    )
    .slice(0, 5);

  return clientNames.length > 0 || clientLogos.length > 0 ? (
    <>
      <CondensedHeader name="EXPERIENCES_IN_HOUSE">
        In House Experience
      </CondensedHeader>
      {clientNames.length > 0 ? (
        <>
          <Divider>
            {clientNames.map(client => {
              return (
                <span data-test="EXPERIENCE_NAME" key={client}>
                  {client}
                </span>
              );
            })}
          </Divider>
          <Gutter bottom="8px" />
        </>
      ) : null}

      {clientLogos.map(clientDomain => {
        return (
          <Gutter right="16px" as="span" key={clientDomain}>
            <CompanyLogo
              name="EXPERIENCE_LOGO"
              src={`${EnvUtil.clearbitLogoUrl}/${clientDomain}`}
              fallback={null}
              alt={`Logo for: ${clientDomain}`}
              width="48px"
            />
          </Gutter>
        );
      })}
    </>
  ) : null;
};
