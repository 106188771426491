import moment from 'moment';
import requestLib from 'superagent';
import FormData from 'form-data';

import {
  APIError,
  BadRequestError,
  AuthorizationError,
  AuthenticationError,
} from '../errors';
import Cookie from '../../utils/browser-cookies';
import { EnvUtil } from '../../utils/env-util';

export const request = async (name, endpoint, method, body, headers = {}) => {
  try {
    const requestObj = requestLib[method.toLowerCase()](`${endpoint}`).set({
      ...(body instanceof FormData
        ? {}
        : { 'Content-Type': 'application/json' }),
      ...headers,
    });

    const response = await requestObj.send(body);
    return response.body;
  } catch (e) {
    const errorTypeExceptions = {
      BLOCKED_EMAIL: true,
    };

    if (!e || !e.response) {
      throw new Error(e);
    }

    const { response } = e;

    const { body: errBody } = response;
    const obj = {
      name,
      endpoint,
      method,
      body: errBody || {},
      headers,
      response,
    };

    switch (response.status) {
      case 400: {
        const errorException = errBody?.errors?.find(
          error => errorTypeExceptions[error.errorType]
        );

        if (!errorException) throw new BadRequestError(obj);
        return errorException;
      }
      case 403: {
        throw new AuthenticationError(obj);
      }
      case 401: {
        Cookie('user', null, {
          domain: EnvUtil.cookieDomain,
          path: '/',
        });
        window.location.reload();
        throw new AuthorizationError(obj);
      }
      default: {
        throw new APIError(obj);
      }
    }
  }
};

export const createPostBody = ({ fields }, pageName) => {
  const currentTime = moment().valueOf();
  const hutk = Cookie('hubspotutk') || null;
  // note that the form_submission_time property is added here to allow our hubspot workflow to access
  // a field with a unique id in order to generate a task.
  // The workflow does not have access to submittedAt.
  let formFields = [
    ...fields,
    { name: 'form_submission_time', value: currentTime },
  ];

  // try to get AB Tasty test history and add it as the a_b_tasty_test_exposures property on the form submission
  try {
    const ABTastyTests = window.ABTasty.getCampaignHistory();
    formFields = [
      ...formFields,
      {
        name: 'a_b_tasty_test_exposures',
        value: JSON.stringify(ABTastyTests),
      },
    ];
  } catch {
    /* empty */
  }

  return {
    submittedAt: currentTime,
    fields: formFields,
    context: {
      hutk,
      pageUri: window.location.href,
      pageName,
    },
  };
};
