import moment from 'moment';

export const EngagementsUtil = {
  isStartDateValid(startDate: string) {
    return (
      startDate?.length > 0 &&
      moment(startDate).isAfter(moment().subtract(1, 'd'))
    );
  },

  isEndDateValid(startDate: string, endDate: string) {
    return !!(
      startDate?.length &&
      endDate?.length > 0 &&
      moment(endDate).isAfter(moment(startDate))
    );
  },

  isBillableHoursValid(weeks: number, billingUnitsPerWeek: number) {
    return weeks * billingUnitsPerWeek >= 20;
  },

  showEngagementLengthWarning(
    startDate: string,
    endDate: string,
    billingUnitsPerWeek: number,
    weeks: number
  ) {
    return (
      startDate &&
      endDate &&
      billingUnitsPerWeek &&
      !(
        this.isStartDateValid(startDate) &&
        this.isEndDateValid(startDate, endDate) &&
        this.isBillableHoursValid(weeks, billingUnitsPerWeek)
      )
    );
  },
};
