import React, { useEffect, useRef, useState } from 'react';
import {
  useApi,
  PDFExport,
  SplitSubmissionPdf,
  StringUtil,
  PdfPageTemplateFooter,
} from '@axiom/ui';
import { useParams } from 'react-router-dom';

import { AccountSubmissionsApi } from '../../api/account/account-submissions';
import { AccountSubmissionsLegacyApi } from '../../api/account/account-submissions-legacy';
import { PracticeAreaApi } from '../../api/practiceAreas';
import { CandidateApi } from '../../api/candidate/candidate';
import { TaxonomyApi } from '../../api/taxonomy/taxonomy';
import { EnvUtil } from '../../utils/env-util';

import { PDFWrapper } from './SplitSubmissionPdfDownloadStyles';

export const SplitSubmissionPdfDownload = () => {
  const { accountId, submissionId } = useParams();
  const [candidateIndex, setCandidateIndex] = useState(0);
  const pdfRoot = useRef(null);
  const [{ data: submission }, { data: practiceAreas }, { data: taxonomy }] =
    useApi(
      AccountSubmissionsApi.readAccountSubmissionDetails(
        accountId,
        submissionId,
        { ctaFilters: ['None', 'Hire', 'Interview'] }
      ),
      PracticeAreaApi.readPracticeAreas(),
      TaxonomyApi.readTaxonomy()
    );

  const candidateIds = submission?.positions?.reduce((crnt, pos) => {
    pos.candidateOpportunities.forEach(co => {
      crnt.push(co.candidate.id);
    });

    return crnt;
  }, []);

  const candidates = useApi(
    ...candidateIds.map(candidateId =>
      AccountSubmissionsLegacyApi.readCandidateAccountSubmissionDetails(
        accountId,
        submissionId,
        candidateId
      )
    )
  ).map(response => response.data);

  const allGroupedExperiences = useApi(
    ...candidateIds.map(candidateId =>
      CandidateApi.readCandidateGroupedExperiences(candidateId, {
        submissionId,
      })
    )
  ).map(response => response.data);

  const cleanCompanyName = StringUtil.cleanStringForFileName(
    submission.jobName
  );

  useEffect(() => {
    if (pdfRoot?.current?.save) {
      setTimeout(() => {
        pdfRoot.current.save(() => {
          if (candidateIndex < candidates.length - 1) {
            setCandidateIndex(candidateIndex + 1);
          }
        });
      }, 500);
    }
  }, [pdfRoot, candidateIndex]);

  const candidate = candidates[candidateIndex];

  return (
    <PDFWrapper data-test="HIDE_RENDERING">
      <PDFExport
        fileName={`${candidate.calculatedDisplayName} ${cleanCompanyName} Axiom Matches.pdf`}
        ref={pdfRoot}
        scale={0.5}
        margin={{ top: '0in', right: '0in', bottom: '0in', left: '0in' }}
        pageTemplate={props => (
          <PdfPageTemplateFooter
            pdfProps={props}
            candidate={candidate}
            isAnonymous={false}
          />
        )}
      >
        <SplitSubmissionPdf
          submission={submission}
          candidate={candidate}
          allGroupedExperiences={allGroupedExperiences}
          practiceAreas={practiceAreas}
          clientUrl={EnvUtil.clientEngagementsUrl}
          loginUrl={EnvUtil.loginUrl}
          taxonomy={taxonomy}
        />
      </PDFExport>
    </PDFWrapper>
  );
};
