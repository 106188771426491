import { orderBy } from 'lodash';
import {
  CandidateImageConst,
  ImageSizeKeysTypes,
  OccupationTypes,
  WeeklyTimeUnits,
} from '@axiom/const';
import { Candidate, Experience, PracticeArea } from '@axiom/validation';

import { PracticeAreaUtil } from './practice-area-util';
import { EnvUtil } from './env-util';

const { IMAGE_RESIZE_DIRECTORY, ImageSizesByWidth, PROFILE_IMAGE_DIRECTORY } =
  CandidateImageConst;

export const CandidateUtil = {
  isParaLegal(candidate: Candidate) {
    return (
      candidate?.occupationType === OccupationTypes.LEGAL_SUPPORT ||
      candidate?.occupationType === OccupationTypes.PARALEGAL
    );
  },

  formatCandidateLocation(
    { addressCity, addressState, addressCountry }: Candidate = {},
    authed: boolean
  ) {
    const location = [];
    if (addressCity && authed) {
      location.push(addressCity);
    }
    if (addressState) {
      location.push(addressState);
    }
    if (addressCountry) {
      location.push(addressCountry);
    }

    return location.length > 0 ? location.join(', ') : null;
  },

  getFormattedLawFirms({ lawFirms }: Candidate) {
    return lawFirms?.length > 3
      ? [...lawFirms.slice(0, 3), { client: '...' }]
      : lawFirms;
  },

  formatYOE(years: string) {
    const numYears = Number.parseInt(years, 10);
    let suffix = ' yr.';
    let prefix = '';

    if (numYears < 1) {
      prefix = '< ';
    } else if (numYears < 2) {
      suffix = '+ yr.';
    }
    if (numYears >= 2) suffix = '+ yrs.';

    return `${prefix}${numYears >= 2 ? numYears : 1}${suffix}`;
  },

  // (practiceAreas state, candidate) ============ START
  formatPracticeAreaSpecialty(
    practiceAreas: PracticeArea[],
    practiceAreaId: PracticeArea['id']
  ) {
    if (!practiceAreaId) {
      return null;
    }

    const { practiceArea, specialty } =
      PracticeAreaUtil.getPracticeAreaAndSpecialty(
        practiceAreas,
        practiceAreaId
      );

    return specialty?.name && practiceArea.name !== specialty.name
      ? `${practiceArea.name} - ${
          specialty.name.toLowerCase() === 'm&a, divestitures'
            ? 'Divestitures'
            : specialty.name
        }`
      : practiceArea.name;
  },
  // (practiceAreas state, candidate) ============ END

  sortExperiences(experiences: Experience[] = [], sortByHighlighted: boolean) {
    const items = ['endDate', 'startDate', 'client'];
    const by: Array<boolean | 'desc' | 'asc'> = ['desc', 'desc', 'asc'];
    if (sortByHighlighted) {
      items.unshift('isHighlighted');
      by.unshift('desc');
    }
    return orderBy(experiences, items, by);
  },

  getProfileImageUri(candidate: Candidate, size: ImageSizeKeysTypes) {
    const { profileImageKey } = candidate;

    if (
      !profileImageKey ||
      !ImageSizesByWidth[size] ||
      !EnvUtil.publicS3Bucket
    ) {
      return null;
    }

    const [idSlug] = profileImageKey
      .replace(`${PROFILE_IMAGE_DIRECTORY}/`, '')
      .split('/');
    const { width, height } = ImageSizesByWidth[size];

    return `${EnvUtil.publicBaseUrlS3}/${EnvUtil.publicS3Bucket}/${IMAGE_RESIZE_DIRECTORY}/${idSlug}/${width}x${height}`;
  },

  calculateBillingHoursPerWeek(
    candidate: Candidate & {
      billingUnitsPerWeek: number;
      weeklyTimeUnit: (typeof WeeklyTimeUnits)[keyof typeof WeeklyTimeUnits];
    }
  ) {
    const { billingUnitsPerWeek = 0, weeklyTimeUnit } = candidate;

    return (
      billingUnitsPerWeek *
      (weeklyTimeUnit === WeeklyTimeUnits.Daily ? 8 : billingUnitsPerWeek)
    );
  },
};
