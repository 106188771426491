import React from 'react';
import { SubmissionPdf, useApi } from '@axiom/ui';
import { useParams } from 'react-router-dom';
import { AccountConst } from '@axiom/const';

import { AccountLegacyApi } from '../../api/account/account-legacy';
import { AccountSubmissionsApi } from '../../api/account/account-submissions';
import { PracticeAreaApi } from '../../api/practiceAreas';
import { AccountSubmissionsLegacyApi } from '../../api/account/account-submissions-legacy';
import { CandidateApi } from '../../api/candidate/candidate';
import { TaxonomyApi } from '../../api/taxonomy/taxonomy';
import { EnvUtil } from '../../utils/env-util';

export const SubmissionPdfData = () => {
  const { accountId, submissionId } = useParams();

  const [
    { data: account },
    { data: submission },
    { data: practiceAreas },
    { data: taxonomy },
  ] = useApi(
    AccountLegacyApi.getAccount(accountId, {
      expand: [AccountConst.Expansions.accountTeamUserInfo],
    }),
    AccountSubmissionsApi.readAccountSubmissionDetails(
      accountId,
      submissionId,
      { ctaFilters: ['None', 'Hire', 'Interview'] }
    ),
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );

  const candidateIds = submission?.positions?.reduce((crnt, pos) => {
    pos.candidateOpportunities.forEach(co => {
      crnt.push(co.candidate.id);
    });

    return crnt;
  }, []);

  const candidates = useApi(
    ...candidateIds.map(candidateId =>
      AccountSubmissionsLegacyApi.readCandidateAccountSubmissionDetails(
        accountId,
        submissionId,
        candidateId
      )
    )
  ).map(response => response.data);

  const allGroupedExperiences = useApi(
    ...candidateIds.map(candidateId =>
      CandidateApi.readCandidateGroupedExperiences(candidateId, {
        submissionId,
      })
    )
  ).map(response => response.data);

  return (
    <SubmissionPdf
      account={account}
      submission={submission}
      candidates={candidates}
      allGroupedExperiences={allGroupedExperiences}
      practiceAreas={practiceAreas}
      talentFinderUrl={
        account.accountType === AccountConst.Types.Direct
          ? `/engagements/account/${account.id}/new-engagement`
          : EnvUtil.hubspotTalentFinder
      }
      newTab={account.accountType !== AccountConst.Types.Direct}
      clientUrl={EnvUtil.clientUrl}
      loginUrl={EnvUtil.loginUrl}
      taxonomy={taxonomy}
    />
  );
};
