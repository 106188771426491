import React from 'react';
import { connect } from 'react-redux';
import {
  Brief,
  Button,
  Gutter,
  HeaderLink,
  ImageCircle,
  Layout,
  Paragraph,
  ParagraphHeader,
  SectionHeader,
  useApi,
} from '@axiom/ui';
import { CandidateImageConst } from '@axiom/const';
import { Link } from 'react-router-dom';

import { CandidateStore } from '../../stores/candidate-store';
import { CandidateUtil } from '../../utils/candidate-util';
import { getSharePath } from '../../utils/link-util';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { FormExternalSearchReachOutModalStore } from '../../stores/form-external-search-reach-out-modal-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { CandidateApi } from '../../api/candidate/candidate';

const { PossibleImageSizes } = CandidateImageConst;

const getAddress = talent => {
  const data = [];
  if (talent.addressState) data.push(talent.addressState);
  if (talent.addressCountry) data.push(talent.addressCountry);

  return data.join(', ');
};

const NearestNeighborComponent = ({ candidate, practiceAreas, user }) => {
  const [{ data: nearestNeighborData } = { data: [] }] = useApi(
    candidate?.id && CandidateApi.readCandidatesSimilarTo(candidate.id)
  );

  return (
    nearestNeighborData?.length > 0 && (
      <>
        <SectionHeader>Similar Talent</SectionHeader>
        <Gutter bottom="16px" />
        <Brief briefMaxChildren={5} pattern="secondary">
          {nearestNeighborData.map(candidateNeighbor => (
            <Gutter bottom="16px" key={candidateNeighbor.id}>
              <Layout position="middle" horizontalGutter="16px">
                <ImageCircle
                  imageName={candidateNeighbor.calculatedDisplayName}
                  src={CandidateUtil.getProfileImageUri(
                    candidateNeighbor,
                    PossibleImageSizes.W_56
                  )}
                />
                <div>
                  <HeaderLink>
                    <Link to={getSharePath(candidateNeighbor, practiceAreas)}>
                      <ParagraphHeader>
                        {candidateNeighbor.calculatedDisplayName}
                      </ParagraphHeader>
                    </Link>
                  </HeaderLink>
                  <Gutter bottom="4px" />
                  {getAddress(candidateNeighbor)}
                </div>
              </Layout>
            </Gutter>
          ))}
          {nearestNeighborData.length > 5 && (
            <div>
              <SectionHeader>Want to see more?</SectionHeader>
              <Gutter bottom="12px" />
              <Paragraph>
                If you'd like help finding more talent like this, including
                available talent whose profiles aren't visible here yet, please
                reach out to Axiom.
              </Paragraph>
              <Gutter bottom="16px" />
              <Button
                onClick={() => {
                  FormExternalSearchReachOutModalStore.load(user);
                }}
                pattern="secondary"
                variation="outline"
              >
                Reach Out
              </Button>
              <Gutter bottom="16px" />
            </div>
          )}
        </Brief>
      </>
    )
  );
};

NearestNeighborComponent.defaultProps = {
  user: null,
};

NearestNeighborComponent.propTypes = {
  candidate: CandidateStore.getDataShape().isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
  user: PreloadedUserStore.getDataShape(),
};

export const NearestNeighbor = connect(state => ({
  practiceAreas: PreloadedPracticeAreasStore.selectData(state),
}))(NearestNeighborComponent);
