import React from 'react';
import { SearchCandidate } from '@axiom/validation';
import { CondensedLarge, Icon } from '@axiom/ui';

export const DiscoverTalentCarouselYearsOfExperience = ({
  candidate,
}: {
  candidate: SearchCandidate;
}) => {
  const candidateYOE = candidate.yearsOfExperience || 0;
  const yoe = Math.round(candidateYOE);
  let data = `${yoe} Year${yoe > 1 ? 's' : ''} of experience`;

  if (yoe === 0) {
    data = 'Less than 1 Year of experience';
  } else if (Math.floor(candidate.yearsOfExperience) >= 20) {
    data = '20+ Years of experience';
  }

  return (
    <>
      <Icon name="checkmark" />
      <CondensedLarge name="DISCOVER_CANDIDATE_EXPERIENCE">
        {data}
      </CondensedLarge>
    </>
  );
};
