import React from 'react';
import { Dropdown, Form, FormLabel, Layout } from '@axiom/ui';
import { TalentSearchSortingSchema } from '@axiom/validation';

import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';
import { SearchFiltersMeta } from '../../models/search-filters';

const SORT_BY_OPTIONS = FormTalentSearchFiltersStore.getSortOptions();
const removedSortCriteria = new Set([
  'hourlyRateLowToHigh',
  'hourlyRateHighToLow',
]);
const filteredSortOptions = SORT_BY_OPTIONS.filter(
  option => !removedSortCriteria.has(option.value)
);

export type TalentSearchSortByProps = {
  filters: SearchFiltersMeta;
  disabled?: boolean;
};

export const TalentSearchSortBy = ({
  filters,
  disabled = false,
}: TalentSearchSortByProps) => {
  return (
    <Form
      name="TALENT_SEARCH_SORT_BY"
      schema={TalentSearchSortingSchema}
      submitOnChange
      initialValues={{
        sortBy: filters.sort ?? filteredSortOptions[0].value,
      }}
      onSubmit={async values => {
        const { sortBy } = values;

        return FormTalentSearchFiltersStore.submit(null, {
          ...filters,
          sort: sortBy || filteredSortOptions[0].value,
        });
      }}
    >
      {() => {
        return (
          <Layout
            position="center middle"
            direction="horizontal"
            horizontalGutter="8px"
          >
            <FormLabel for="sortBy">Sort by</FormLabel>
            <Dropdown
              placeholder={filteredSortOptions[0].label}
              options={filteredSortOptions}
              name="sortBy"
              displayKey="label"
              valueKey="value"
              disabled={disabled}
            />
          </Layout>
        );
      }}
    </Form>
  );
};
