import { UserConst } from '@axiom/const';

const {
  UserRoles: { EnvoyUser, EnvoySales, EnvoyAdmin, ClientUser, LPUser },
} = UserConst;

const activeRolesSet = roles => {
  const activeRoles =
    roles === null || (Array.isArray(roles) && roles.length === 0)
      ? Object.values(UserConst.UserRoles)
      : roles;

  return new Set(activeRoles);
};

export const isLoggedInUser = user => {
  return !!(user?.id && user?.roles);
};

export const userRolesIncludesOneOf = (user, roles) => {
  if (!isLoggedInUser(user)) return false;

  const rolesSet = activeRolesSet(roles);

  return !!user?.roles?.some(role => rolesSet.has(role));
};

export const getBenchRoles = () => [
  EnvoyUser,
  EnvoySales,
  EnvoyAdmin,
  ClientUser,
];

export const isBenchUser = user =>
  userRolesIncludesOneOf(user, getBenchRoles());

export const isLpUser = userClient =>
  userRolesIncludesOneOf(userClient, [LPUser]);

export const isEnvoyUser = userClient =>
  userRolesIncludesOneOf(userClient, [EnvoyUser, EnvoySales, EnvoyAdmin]);

export const isClientUser = userClient =>
  userRolesIncludesOneOf(userClient, [ClientUser]);

export const isAFCUser = user => isEnvoyUser(user) || isClientUser(user);
