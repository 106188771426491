import React, { useEffect } from 'react';
import {
  Gutter,
  Button,
  Card,
  Grid,
  GridRow,
  GridColumn,
  LargeHeader,
  Paragraph,
  CardSection,
  CardFooter,
} from '@axiom/ui';
import { connect } from 'react-redux';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import SeeMoreIcon from '../../public/images/see-more-people.png';
import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';
import { FormExternalSearchReachOutModalStore } from '../../stores/form-external-search-reach-out-modal-store';
import { EndOfTalentSearchStore } from '../../stores/end-of-talent-search-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';

const getStrings = count => {
  const data = {};

  if (count > 0) {
    data.title = 'Looking for more talent like this?';
    data.body = (
      <>
        We have {count} more profile(s) like these for Axiom talent who are
        available but aren’t shown here yet. Please reach out if you’d like to
        find out more about them.
      </>
    );
  } else {
    data.title = `Want to see more?`;
    data.body = `To see more profiles, try updating or clearing your filters. If you’d like help finding more talent like this, including talent not shown on this page, please reach out to Axiom.`;
  }

  return data;
};

const SeeMoreCardComponent = ({ endOfTalentState, activeFilters, user }) => {
  useEffect(() => {
    EndOfTalentSearchStore.load(activeFilters);
  }, [activeFilters]);

  return (
    <StoreStateTreatment
      allowOverflow
      name="SEE_MORE_FINAL_CARD"
      storeState={endOfTalentState}
      showRefetchLoader={false}
      renderLoadedView={({ data: { endOfTalentCount } }) => {
        const { title, body } = getStrings(endOfTalentCount);

        return (
          <Card>
            <CardSection divider>
              <Grid>
                <GridRow>
                  <GridColumn
                    largeScreenWidth={2}
                    tabletWidth={3}
                    mobileWidth={12}
                  >
                    <img
                      width="100"
                      height="100"
                      src={SeeMoreIcon}
                      alt="see-more-people"
                    />
                  </GridColumn>
                  <GridColumn
                    largeScreenWidth={10}
                    tabletWidth={9}
                    mobileWidth={12}
                  >
                    <Gutter top="24px" only="mobile" />
                    <Gutter bottom="16px">
                      <LargeHeader>{title}</LargeHeader>
                    </Gutter>
                    <Paragraph>{body}</Paragraph>
                  </GridColumn>
                </GridRow>
              </Grid>
            </CardSection>
            <CardFooter>
              <Button
                variation="outline"
                name="SEE_MORE_CLEAR_FILTERS"
                onClick={() => {
                  FormTalentSearchFiltersStore.submit();
                }}
              >
                Clear Filters
              </Button>
              <Button
                name="SEE_MORE_REACH_OUT"
                onClick={() => {
                  FormExternalSearchReachOutModalStore.load(user);
                }}
              >
                Reach Out
              </Button>
            </CardFooter>
          </Card>
        );
      }}
    />
  );
};

SeeMoreCardComponent.defaultProps = {
  activeFilters: {},
  user: null,
};

SeeMoreCardComponent.propTypes = {
  user: PreloadedUserStore.getDataShape(),
  activeFilters: FormTalentSearchFiltersStore.getSelectedFiltersShape(),
  endOfTalentState: EndOfTalentSearchStore.getStateShape().isRequired,
};

export const TalentSearchSeeMoreCard = connect(state => ({
  endOfTalentState: EndOfTalentSearchStore.select(state),
}))(SeeMoreCardComponent);
